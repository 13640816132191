import React, {Fragment} from 'react';
import styles from './HistoryCard.module.css'
import {History} from "../../content/history";
import FallbackImage from '../../images/history/history_fallback.svg'
import {Link} from "react-router-dom";
import {scrollToTop} from "../../utils/utils";

interface Props {
    history: History;
}

const HistoryCard = ({ history }: Props) => {

    const {image, title, content, subtitle, slug} = history;

    const replace = (input: string) => {
        return input.split('\n').map((item, key) => {
            return <Fragment key={key}>{item}<br/></Fragment>
        })
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.imageWrapper}>
                <img src={image || FallbackImage} alt={title} />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.titleWrapper}>
                    <h6 className={styles.title}>{title}</h6>
                    <span className={styles.subtitle}>{subtitle}</span>
                </div>
                <p className={styles.content}>
                    {replace(content)}
                </p>
                {slug && (
                    <Link onClick={scrollToTop} className={styles.cta} to={`/history/${slug}`}>
                        Read More
                    </Link>
                )}

            </div>
        </div>
    );
}

export default HistoryCard;
