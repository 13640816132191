import React, {useState} from 'react';
import styles from './YourVoiceCard.module.css'
import FallbackImage from '../../images/history/history_fallback.svg'
import {YourVoiceWinner} from "../../content/yourVoiceContent";
import YourVoiceDialog from "./YourVoiceDialog";
import classNames from "classnames";

interface Props {
    winner: YourVoiceWinner;
}

const YourVoiceCard = ({ winner }: Props) => {

    const {image, title, content, description, readMore, author} = winner;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.wrapper}>
            <div className={styles.imageWrapper}>
                <img src={image || FallbackImage} alt={title} />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.titleWrapper}>
                    <h6 className={styles.title}>{title}</h6>
                    <span className={styles.subtitle}>{description}</span>
                </div>
                <p className={styles.content}>
                    {content}
                </p>
                <p>
                    <span className={styles.submitter}>Idea submitter</span><br/>
                    <span className={styles.author}>{author}</span>
                </p>
                <div className={classNames(styles.cta)} onClick={() => setIsOpen(true)}>Read more</div>
            </div>
            <YourVoiceDialog isOpen={isOpen} onClose={() => setIsOpen(false)} content={readMore} title={title} />
        </div>
    );
}

export default YourVoiceCard;
