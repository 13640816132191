import React from 'react';
import styles from './MemberCard.module.css'
import { Member } from "../../content/members";

interface Props {
    member: Member;
}

const MemberCard = ({ member }: Props) => {

    const {image, title, name, quote} = member;

    return (
        <div className={styles.wrapper}>
            <img src={image} alt={name}/>
            <div className={styles.textWrapper}>
                <h5>{name}</h5>
                <span>{title}</span>
            </div>
            <p dangerouslySetInnerHTML={{__html: quote}} />
        </div>
    );
}

export default MemberCard;
