import React from 'react';
import styles from './HeroHistory.module.css';
import classNames from "classnames";


interface Props {
    title: string;
    image: string;
}

const HeroHistory = ({title, image} : Props) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.imageWrapper}>
                <img src={image} alt={title} />
            </div>
            <div className={classNames('container', styles.textWrapper)}>
                <h1 className={styles.title}>{title}</h1>
            </div>
        </div>
    );
}

export default HeroHistory;
