import React, {useEffect, useState} from "react";
import styles from "./YourVoice.module.css";
import classNames from "classnames";
import {YOUR_VOICE_TOPICS} from "../../content/yourVoiceContent";
import YourVoiceContent from "./YourVoiceContent";

const YourVoice = () => {
    const [activeTopic, setActiveTopic] = useState(YOUR_VOICE_TOPICS[0]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>
                {YOUR_VOICE_TOPICS.map((topic) => {
                    const isActive = topic.title === activeTopic.title;

                    return (
                        <div className={classNames(styles.tab, isActive && styles.active)} onClick={() => setActiveTopic(topic)} key={topic.title}>
                            {topic.title}
                        </div>
                    )
                })}
            </div>

            <div className={styles.contentWrapper}>
                <YourVoiceContent topic={activeTopic} />
            </div>
        </div>
    );
}

export default YourVoice;