import EmptyImage from '../images/members/svg/empty.svg';
import Judge_1 from '../images/judges/judge_1.svg';
import Judge_2 from '../images/judges/judge_2.svg';
import Judge_3 from '../images/judges/judge_3.svg';
import Judge_4 from '../images/judges/judge_4.svg';

export interface Judge {
    name: string;
    available: string;
    title: string;
    id: number;
    image: string;
}

export const JUDGES: Judge[] = [
    {
        id: 1,
        name: 'Endre Nesset',
        title: 'President B2C',
        available: '15.08',
        image: Judge_1
    },
    {
        id: 2,
        name: 'Giuseppe Gardali',
        title: 'President B2B',
        available: '22.08',
        image: Judge_2
    },
    {
        id: 3,
        name: 'Jan Roos',
        title: 'CTO',
        available: '29.08',
        image: Judge_3
    },
    {
        id: 4,
        name: 'Evelin Simer',
        title: 'Head of Legal & Compliance',
        available: '05.09',
        image: Judge_4
    },
    {
        id: 5,
        name: 'Mystery Judge',
        title: 'Will be announced during the event!',
        available: '12.09',
        image: EmptyImage
    },
]
