import React from 'react';
import styles from './MobileLoginButton.module.css'
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { loggedInUserStore, User } from "../../stores/stores";
import { useStore } from "@nanostores/react";
import jwtDecode from "jwt-decode";
import classNames from "classnames";
import logoutIcon from '../../images/icons/logout.svg'


interface DecodedUser {
    name?: string;
    picture?: string;
}

const MobileLoginButton = () => {

    const loggedInUser = useStore(loggedInUserStore);

    const handleFailedLogin = () => {
        console.log('Login failed')
    }

    const handleLogout = () => {
        loggedInUserStore.set(undefined);
    }

    const handleSuccessLogin = (response: CredentialResponse) => {
        if (response.credential) {
            const decoded = jwtDecode(response.credential) as unknown as DecodedUser
            const user: User = {
                name: decoded.name || 'N/A',
                imageUrl: decoded.picture || 'N/A'
            }

            loggedInUserStore.set(user);
        }
    }

    return (
        <div className={styles.wrapper}>
            {loggedInUser
                ?
                (
                    <div className={styles.userWrapper}>
                        {/*<img src={loggedInUser.imageUrl} alt={loggedInUser.name} />*/}
                        <div className={classNames(styles.logout)} onClick={handleLogout}>
                            <div className={styles.imageWrapper}>
                                <img src={logoutIcon} alt="Logout"/>
                            </div>
                            <span>Logout</span>
                        </div>
                    </div>
                )
                :
                (
                    <GoogleLogin
                        useOneTap={true}
                        onSuccess={handleSuccessLogin}
                        onError={handleFailedLogin}
                    />
                )
            }
        </div>
    );
}

export default MobileLoginButton;
