import styles from "./YourVoiceDialog.module.css";
import React, {useEffect} from "react";
import Modal from 'react-modal';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    content: React.ReactNode;
    title: string;
}

Modal.setAppElement('#modal-root');

const YourVoiceDialog = ({isOpen,onClose, content, title}: Props) => {

    useEffect(() => {
        if(isOpen) {
            document.body.classList.add('scrollBlock')
        }
        else{
            document.body.classList.remove('scrollBlock')
        }
    }, [isOpen]);

    return (
        <Modal className={styles.dialog} overlayClassName={styles.overlay} isOpen={isOpen} onRequestClose={onClose}>
            <div className={styles.header}>
                <div className={styles.title}>{title}</div>
                <div className={styles.close} onClick={onClose}>X</div>
            </div>
            <div className={styles.content}>{content}</div>
            <div className={styles.footer}>
                <div onClick={onClose} className={styles.cta}>Close</div>
            </div>
        </Modal>
    )
}

export default YourVoiceDialog;
