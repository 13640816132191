import React, { useEffect, useState } from 'react';
import styles from './JudgeCard.module.css';
import EmptyImage from '../../images/members/svg/empty.svg';
import AvailableImage from '../../images/judges/available.svg';
import NiceTryImage from '../../images/judges/nice_try.svg';
import { Judge } from "../../content/judges";
import moment from "moment";
import classNames from "classnames";

interface Props {
    judge: Judge,
    isLocalStorageUnlocked: boolean,
    onUnlock: (id: number) => void
}

const JudgeCard = ({ judge, isLocalStorageUnlocked, onUnlock }: Props) => {
    const dateParts = judge.available.split('.');
    const availableData =  moment(`2023-${dateParts[1]}-${dateParts[0]} 10:00:00`).utc(true);
    const isPastUnlockTime = availableData.isBefore(moment().utc(true));

    // if(judge.id ===1 ) {
    //     console.log('-----')
    //     console.log(availableData.toISOString())
    //     console.log('curr', moment().utc(true).toISOString())
    //     console.log('isBefore', availableData.isBefore(moment().utc(true)));
    // }

    const [name, setName] = useState(`Judge #${judge.id}`);
    const [title, setTitle] = useState('Locked');
    const [image, setImage] = useState(EmptyImage);
    const [description, setDescription] = useState(`Unlock on ${judge.available}`)
    const [isUnlocked, setIsUnlocked] = useState(false)
    const [isBeingUnlocked, setIsBeingUnlocked] = useState(false);
    const [isAvailable, setIsAvailable] = useState(isPastUnlockTime && !isLocalStorageUnlocked);

    useEffect(() => {
        if(isAvailable) {
            setImage(AvailableImage)
            setDescription('Click to unlock')
        }
    }, [isAvailable]);

    useEffect(() => {
        if(isUnlocked) {
            setName(judge.name);
            setTitle(judge.title);
            setImage(judge.image);
            setDescription('')
        }

    }, [isUnlocked]);

    useEffect(() => {
        if(isLocalStorageUnlocked){
            if(isPastUnlockTime) {
                setIsUnlocked(true);
            }
            else {
                setCheaterData();
            }
        }
    }, [isLocalStorageUnlocked]);

    const setCheaterData = () => {
        setImage(NiceTryImage);
        setName('Nice try!');
        setTitle(`But it didn't work`);
        setDescription(`Please wait until ${judge.available}`);
    }

    const onClick = () => {
        if(!isAvailable){
            return;
        }

        setIsBeingUnlocked(true);
        setTimeout(() => {
            setIsUnlocked(true);
            setIsAvailable(false);
        }, 1500)
        setTimeout(() => {
            setIsBeingUnlocked(false);
            onUnlock(judge.id);
        }, 3000)
    }

    return (
        <div className={classNames(styles.wrapper, isAvailable && styles.unlockable, isBeingUnlocked && styles.blur)} onClick={onClick}>
            <img src={image} alt={name}/>
            <div className={styles.textWrapper}>
                <h5>{name}</h5>
                <span>{title}</span>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default JudgeCard;
