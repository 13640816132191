import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/reset.css';
import './css/styles.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId="722644350025-jq5tph6ia19ke1lhbh49g5i0m9vs3oqb.apps.googleusercontent.com">
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </GoogleOAuthProvider>
  </React.StrictMode>
);
