export interface MenuItem {
    url: string;
    name: string;
    protected: boolean;
}

export interface FooterMenuItem {
    url: string;
    name: string;
}

export interface FooterMenuSection {
    name: string;
    menuItems: FooterMenuItem[]
}

export const MENU_ITEMS: MenuItem[] = [
    {
        url: '/',
        name: 'Home',
        protected: false
    },
    // {
    //     url: '/program',
    //     name: 'Program',
    //     protected: true
    // },
    // {
    //     url: '/faq',
    //     name: 'FAQ',
    //     protected: true
    // },
    {
        url: '/history',
        name: 'History',
        protected: true
    },
    {
        url: '/your-voice',
        name: 'Your Voice',
        protected: true
    },
    // {
    //     url: '/ideas',
    //     name: 'Ideas',
    //     protected: true
    // },
    {
        url: '/team',
        name: 'Team',
        protected: true
    },
];

export const FOOTER_MENU_SECTIONS: FooterMenuSection[] = [
    {
        name: 'Event Info',
        menuItems: [
            {
                url: 'program#info',
                name: 'Event Info'
            },
            // {
            //     url: 'prizes#prizes',
            //     name: 'Prizes'
            // },
            {
                url: 'program#judges',
                name: 'Judges'
            },
            {
                url: 'faq#faq',
                name: 'FAQ'
            }
        ]
    },
]


