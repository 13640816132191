import React, {useEffect, useState} from 'react';
import Hero from "../components/hero/Hero";
import styles from './TopicsPage.module.css'
import classNames from "classnames";
import {pageTitleStore} from "../stores/stores";
import {useLocation} from "react-router-dom";
import {History, HISTORY_EVENTS} from "../content/history";
import HeroHistory from "../components/hero/HeroHistory";
import HistoryDetails from "../components/history/HistoryDetails";

const HistoryDetail = () => {
    const location = useLocation()
    const [history, setHistory] = useState<History | undefined>(undefined)

    useEffect(() => {
        if(!location){
            return;
        }

        const slug = location.pathname.split('/')[2].toLowerCase();
        const historyEvent = HISTORY_EVENTS.find((history) => history.slug === slug);

        const pageTitle = historyEvent?.title || 'Page Not Found';
        pageTitleStore.set(pageTitle);
        setHistory(historyEvent)

    }, [location]);

    if(!history) {
        return (
            <>
                <Hero title="Page Not Found"/>
                <div className={classNames('container', 'mainContent', 'flexGrow', styles.wrapper)}>
                    Page Not Found
                </div>
            </>
        );
    }

    return (
        <>
            <HeroHistory title={history.title} image={history.backgroundImage!}/>
            <div className={classNames('container', 'mainContent', 'flexGrow', styles.wrapper)}>
                <HistoryDetails history={history} />
            </div>
        </>
    );
}

export default HistoryDetail;
