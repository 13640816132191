import React, {useEffect, useState} from 'react';
import styles from './HistoryDetails.module.css';
import {History} from "../../content/history";
import classNames from "classnames";
import FallbackImage from '../../images/history/history_fallback.svg';
import {replaceNewLines} from "../../utils/utils";
import {pageTitleStore} from "../../stores/stores";

interface Props {
    history: History;
}

enum HistoryTab {
    SUBMISSIONS = 'SUBMISSIONS',
    OVERVIEW = 'OVERVIEW',
    GALLERY = 'GALLERY',
}

const HistoryDetails = ({ history }: Props) => {

    const [activeTab, setActiveTab] = useState<HistoryTab>(HistoryTab.OVERVIEW);

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <h6 className={styles.title}>{history.title}</h6>
                <div className={styles.tabsWrapper}>
                    <div className={classNames(styles.tab, activeTab === HistoryTab.OVERVIEW && styles.tabActive)} onClick={() => setActiveTab(HistoryTab.OVERVIEW)}>Overview</div>
                    <div className={classNames(styles.tab, activeTab === HistoryTab.SUBMISSIONS && styles.tabActive)} onClick={() => setActiveTab(HistoryTab.SUBMISSIONS)}>Submissions</div>
                    <div className={classNames(styles.tab, activeTab === HistoryTab.GALLERY && styles.tabActive)} onClick={() => setActiveTab(HistoryTab.GALLERY)}>Gallery</div>
                </div>
            </div>
            <div className={styles.content}>
                {activeTab === HistoryTab.OVERVIEW && (
                    <div className={styles.overview}>
                        {history.overviewItems?.map((item) => {
                            return (
                                <div key={item.title} className={styles.overviewItem}>
                                    <span>{item.title}</span>
                                    <p>
                                        {item.content}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                )}
                {activeTab === HistoryTab.SUBMISSIONS && (
                    <div className={styles.submissionList}>
                        {history.submissionSections?.map((section) => {
                           return (
                               <div className={styles.submissionSection} key={section.title}>
                                   <h4>{section.title}</h4>
                                   <div className={styles.submissions}>
                                       {section.submissions.map((submission) => {
                                           return (
                                            <div className={styles.submission} key={submission.title}>
                                                <div className={classNames(styles.imageWrapper, !submission.image && styles.noImage)}>
                                                    <img className={classNames(styles.image, !submission.image && styles.imageSmall)} src={submission.image || FallbackImage} alt={submission.title}/>
                                                </div>
                                                <div className={styles.submissionContentWrapper}>
                                                    <div className={styles.submissionTitleWrapper}>
                                                        <h5>{submission.title}</h5>
                                                        {submission.subtitle && <span className={styles.submissionSubtitle}>{submission.subtitle}</span>}
                                                    </div>
                                                    <p className={styles.submissionContent}>
                                                        {submission.content}
                                                    </p>
                                                    <div className={styles.membersWrapper}>
                                                        <span className={styles.membersTitle}>Members:</span>
                                                        <p>{submission.members}</p>
                                                    </div>
                                                </div>
                                            </div>
                                           )
                                       })}
                                   </div>
                               </div>
                           )
                        })}
                    </div>
                )}
                {activeTab === HistoryTab.GALLERY && (
                    <div className={styles.gallery}>
                        <p className={styles.galleryText}>
                            {replaceNewLines(history.pictures?.content!)}
                        </p>
                        <p className={styles.galleryText}>
                            <span className={styles.gallerySpan}>Pictures:</span>
                            <a href={history.pictures?.url!} target="_blank">Link to Google Drive</a>
                        </p>
                    </div>
                )}
            </div>


        </div>
    );
}

export default HistoryDetails;
