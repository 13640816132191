import React from "react";
import styles from "./YourVoiceResults.module.css";
import YourVoiceCard from "./YourVoiceCard";
import {YOUR_VOICE_WINNERS} from "../../content/yourVoiceContent";

const YourVoiceResults = () => {

    return (
        <div className={styles.wrapper}>
            <h3 className={styles.title}>Special Thanks</h3>
            <div className={styles.cards}>
                {YOUR_VOICE_WINNERS.map((winner, index) => {
                    return <YourVoiceCard winner={winner} key={index}/>
                })}

            </div>
            <div className={styles.thanks}>
                <p className={styles.bold}>🌟 Thank You for Your Creative Contributions:</p>
                <p>Frederik Kjuel Sovang, Iris Elisabeth Pihelgas, Thomas Pasgaard Hviid, Edvard Kingissepp, Benyamin Teymoori, Ignatijs Nikulsons, Joseph Hermann Villeneuve, Gregor Sedrik, Murat Can Simsek, Sreekanth Harigovindan, Aleksander Butuzov, Jevgeni Kapparov, Mariana Daemon Iglesias, Endre Nesset, Dinithi Paliskara, Mikael Ketonen, Olavi Ottenson</p>
                <p>Thank you for taking the time to submit your ideas!</p>
                <p>We were blown away by the creativity and thoughtfulness in each submission. We found all the submissions valuable and will consider them for upcoming projects.</p>
                <p>Thank you again for your participation!</p>
            </div>
        </div>
    );
}

export default YourVoiceResults;
