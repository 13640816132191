import React from "react";

export interface EventInfo {
    title: string;
    content: React.ReactNode;
}

export const EVENT_INFOS: EventInfo[] = [
    {
        title: 'Event',
        content:
            <>
                <p>
                    Discover the Power of a Hackathon - Where Innovation Meets Fun!
                </p>

                <p>
                    A hackathon is a high-energy, time-bound event where teams collaborate intensively to ideate, design, and develop innovative solutions to specific challenges.
                </p>

                <p>
                    By organising a hackathon within our company, we aim to foster creativity, team spirit, and problem-solving skills among our employees while having a blast! It's not just about driving innovation, but also creating a fun and engaging experience that brings everyone together, strengthens bonds, and sparks new ideas.
                </p>

                <p>
                    Moreover, it's a fantastic opportunity for our hardworking employees to take a refreshing break from their daily tasks and immerse themselves in exciting and inspiring projects.
                </p>
            </>
    },
    {
        title: 'Ideas',
        content:
            <>
                <p>
                    Unleash Your Imagination: All ideas are not only welcomed but encouraged! <br/>
                    Consider what excites you and what you'd find enjoyable to work on with others.
                </p>

                <p>
                    Need Inspiration?<br />
                    Here are some prompts to spark your creativity: New office, innovative ways to enhance our lives, and the wonders of AI.
                </p>

                <p>
                    While you're free to explore these prompts, feel free to venture beyond them—our goal is to provide helpful inspiration for your remarkable projects.
                </p>
            </>
    },
    {
        title: 'Schedule',
        content:
            <>
                <p>Stay tuned for the detailed schedule coming soon!</p>

                <p className='bold'>Here's basic timeline:</p>

                <p>
                    Wed, 13th September:<br />
                    Welcome, Team Formation, and Hacking Begins
                </p>

                <p>
                    Thur, 14th September:<br />
                    Hackathon Continues
                </p>

                <p>
                    Fri, 15th September:<br />
                    Presentations and Demos of Ideas, Event Concludes
                </p>
            </>
    },
    {
        title: 'Prizes',
        content:
            <>
                <p>
                    Get Ready for Exciting Prizes!
                </p>

                <p>
                    <span className='bold'>Main Prizes:</span><br/>
                    1st prize - 1500 EUR<br />
                    2nd prize - 1000 EUR<br />
                    3rd prize - 500 EUR
                </p>

                <p>
                    The winners will be selected by our esteemed 5-member jury!
                </p>

                <p>
                    And That's Not All!<br />
                    in addition, there will also be a category where everyone will be the judge - <span className='bold'>People's Choice!</span><br />
                    During the final presentation of all ideas, while the judges deliberate on the main winners, all participants get a chance to vote for their favourite idea!<br />
                    Remember, you cannot vote for your own project, and you only get one vote.
                </p>

                <p>
                    The People's Choice prize will be revealed as a delightful surprise 🤫
                </p>
            </>
    },
    {
        title: 'Presentation',
        content:
            <>
                <p>
                    Exciting Changes this Year!
                </p>

                <p>
                    We're spicing things up with the addition of jurors who will evaluate both your ideas and your presentations, just like in the TV series "Dragon's Den."
                </p>

                <p>
                    The main criteria they'll assess include:

                    <ul>
                        <li>Originality: How unique is your idea?</li>
                        <li>Usefulness: Does your project offer practical value?</li>
                        <li>Readiness: How prepared is your project at the end of the presentation?</li>
                        <li>Viability: Is your idea feasible and sustainable?</li>
                        <li>Presentation: Was your pitch engaging and interactive?</li>
                    </ul>
                </p>

                <p>
                    Prepare to showcase your innovation prowess and leave the jury impressed! 🚀🔥
                </p>

                <p>
                    Stay tuned for further details on how to present your ideas. More information will be provided soon!
                </p>
            </>
    }
];
