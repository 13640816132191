import React, { useState } from 'react';
import styles from './FAQTopicList.module.css';
import classNames from "classnames";
import { FAQ_TOPICS } from "../../content/topics";
import FAQQuestions from "./FAQQuestions";

const FAQTopicList = () => {
    const [activeFAQTopic, setActiveFAQTopic] = useState(FAQ_TOPICS[0]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>
                {FAQ_TOPICS.map((faqTopic) => {
                    const isActive = faqTopic.title === activeFAQTopic.title;

                    return (
                        <div className={classNames(styles.tab, isActive && styles.active)} onClick={() => setActiveFAQTopic(faqTopic)} key={faqTopic.title}>
                            {faqTopic.title}
                        </div>
                    )
                })}
            </div>

            <div className={styles.contentWrapper}>
                <FAQQuestions questions={activeFAQTopic.questions} />
            </div>
        </div>
    );
}

export default FAQTopicList;
