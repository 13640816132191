import ErvinImage from '../images/members/svg/ervin.svg';
import HenriImage from '../images/members/svg/henri.svg';
import AlexisImage from '../images/members/svg/alexis.svg';
import TagneImage from '../images/members/svg/tagne.svg';
import DannyImage from '../images/members/svg/danny.svg';
import EkaterinaImage from '../images/members/svg/ekaterina.svg';
import EmptyImage from '../images/members/svg/empty.svg';

export interface Member {
    image: string;
    name: string;
    title: string;
    quote: string;
}

export const MEMBERS: Member[] = [
    {
        image: ErvinImage,
        name: 'Ervin Järvlepp',
        title: 'Senior Vice President, Global Marketing',
        quote: `🚀 Let’s make innovation happen!`
    },
    {
        image: AlexisImage,
        name: 'Alexis Matheis',
        title: 'Marketing Product Owner',
        quote: `I will take the meeting notes!`
    },
    {
        image: HenriImage,
        name: 'Henri Mikkola',
        title: 'Head Of Sportsbook Product Manager',
        quote: `Hello?!`
    },
    {
        image: TagneImage,
        name: 'Tagne Orav',
        title: 'Design; UI/UX',
        quote: `Leave the design to me! 🧑‍🎨`
    },
    {
        image: DannyImage,
        name: 'Danny Hobo',
        title: 'Casino Features Team Lead',
        quote: `It works on my machine! 🤷`
    },
    {
        image: EkaterinaImage,
        name: 'Ekaterina Sulima',
        title: 'Executive Assistant',
        quote: `
            *Task that no one wants to do*<br/>
            Me: I'll do it 😍
        `
    },
    {
        image: EmptyImage,
        name: 'You',
        title: 'Could Be Here',
        quote: `The most fun time of my life`
    }
]
