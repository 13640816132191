import React from "react";
import styles from "./YourVoiceBanner.module.css";
import {Link} from "react-router-dom";
import logo from "../../images/coollab_logo.png";
import classNames from "classnames";
import {scrollToTop} from "../../utils/utils";


const YourVoiceBanner = () => {
    return (
        <div className={styles.outerWrapper}>
            <div className={classNames(styles.wrapper, 'container')}>
                <div className={styles.logoWrapper}><img src={logo} alt="CoolLab"/></div>
                <div className={styles.textWrapper}>
                    <h3 className={styles.title}>Have cool ideas?</h3>
                    <p>Do you have cool ideas that Coollab could help you with?<br />
                        Don’t hesitate to reach out to our members</p>
                    <div>
                    <Link onClick={scrollToTop} to="/team" className="cta" >View members</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default YourVoiceBanner;