import React from 'react';
import Hero from "../components/hero/Hero";
import styles from './TopicsPage.module.css'
import classNames from "classnames";
import JudgesList from "../components/judges/JudgesList";
import EventInfoList from "../components/eventInfo/EventInfoList";
import {pageTitleStore} from "../stores/stores";
import HistoryList from "../components/history/HistoryList";

const History = () => {
    pageTitleStore.set('History')

    return (
        <>
            <Hero title="History" />
            <div className={classNames('container', 'mainContent', 'flexGrow', styles.wrapper)}>
                <HistoryList />
            </div>
        </>
    );
}

export default History;
