import React from 'react';
import styles from './Team.module.css'
import classNames from "classnames";
import {pageTitleStore} from "../stores/stores";
import Hero from "../components/hero/Hero";
import YourVoice from "../components/yourVoice/YourVoice";
import YourVoiceBanner from "../components/yourVoice/YourVoiceBanner";

const subTitle = `Make your voice heard within the company and actively participate in the creation process. 
Your input drives innovation!
    `;

const YourVoicePage = () => {
    pageTitleStore.set('Your Voice')

    return (
        <>
            <Hero title="Your Voice Matters" subTitle={subTitle} />
            <div className={classNames('container', 'mainContent', 'flexGrow', styles.wrapper)}>
                <YourVoice />
            </div>

            <YourVoiceBanner />
        </>
    );
}

export default YourVoicePage;
