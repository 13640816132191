import React from 'react';
import styles from './MobileMenu.module.css'
import lock from '../../images/icons/lock.svg'
import { MENU_ITEMS } from "../../config/menuItems";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { loggedInUserStore, mobileMenuStore } from "../../stores/stores";
import { useStore } from "@nanostores/react";
import MobileLoginButton from "../login/MobileLoginButton";
import slackIcon from "../../images/icons/slack_menu.svg";
import SlackButton from "../buttons/SlackButton";

const MobileMenu = () => {

    const loggedInUser = useStore(loggedInUserStore);

    const handleOnClick = () => {
        mobileMenuStore.set(false)
    }

    return (
        <div className={styles.wrapper}>
            <nav className={styles.navigation}>
                {MENU_ITEMS.map((menuItem) => {

                    const isDisabled = menuItem.protected && !loggedInUser;

                    return (
                        <div key={menuItem.name} onClick={handleOnClick}>
                            <NavLink to={menuItem.url} className={({ isActive }) => {
                                return classNames(styles.navigationItem, isActive && styles.active, isDisabled && styles.disabled)
                            }}>
                                <span className={styles.spanGrid}>{menuItem.name} {isDisabled && <img src={lock} alt="Protected route" />}</span>
                            </NavLink>
                        </div>
                    );
                })}
            </nav>
            <div className={styles.divider} />
            <MobileLoginButton />
            {loggedInUser && (<SlackButton />)}
        </div>
    );
}

export default MobileMenu;
