import React from 'react';
import styles from './Team.module.css'
import coollabLogo from '../images/logos/coollab_logo.svg';
import classNames from "classnames";
import HeroWithImage from "../components/hero/HeroWithImage";
import MembersList from "../components/about/MembersList";
import {pageTitleStore} from "../stores/stores";

const subTitle = `Coollab was initially started back in 2017.
        Although it was working under a different name, the concept was still the same. To build and launch innovative ideas, with the help of everyone in the organisation!\n
        Today, we hold firm to the same values.
        Our doors are always open for new members. Join us and gather more ideas and let's take our Innovation rocket to new heights.
    `;

const Team = () => {
    pageTitleStore.set('About Us')

    return (
        <>
            <HeroWithImage title="About Coollab" subTitle={subTitle} image={coollabLogo} />
            <div className={classNames('container', 'mainContent', 'flexGrow', styles.wrapper)}>
                <div className={styles.titleWrapper}>
                    <h2>Members</h2>
                </div>

                <MembersList />
            </div>
        </>
    );
}

export default Team;
