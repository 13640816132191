import React from "react";
import { NavLink} from "react-router-dom";
import {scrollToTop} from "../utils/utils";
import YourVoiceResults from "../components/yourVoice/YourVoiceResults";
import FoxBox from '../images/yourVoice/fox-box.svg';
import ThoughtTrough from '../images/yourVoice/thought-through.svg';
import CompetitorAnalysis from '../images/yourVoice/competitor-analysis.svg';
import RebornIdea from '../images/yourVoice/reborn-idea.svg';

export interface YourVoiceTopicType {
    title: string;
    contents: YourVoiceContentType[];
}

export interface YourVoiceContentType {
    title: string;
    content: React.ReactNode;
}

export interface YourVoiceWinner {
    title: string;
    description: string;
    content: React.ReactNode;
    author: string;
    readMore: React.ReactNode;
    image: string;
}

export const YOUR_VOICE_WINNERS: YourVoiceWinner[] = [{
    title: 'Out of the fox',
    description: 'Most out of the box idea',
    content: <p>A subscription-based loyalty program provides steady revenue streams and personalized rewards, fostering customer engagement. By tailoring rewards to individual preferences and collaborating with partner brands, we enrich the program's value proposition. This approach encourages ongoing interaction with our platform, building a sense of community and increasing customer retention over time.</p>,
    author: 'Firat Polat',
    readMore: <>
                <p>Predictable Revenue Streams: Unlike traditional loyalty programs where rewards are often given based on actions or purchases, a subscription-based model allows us to generate recurring revenue streams. Customers sign up for a monthly or yearly subscription, providing us with a predictable income while incentivizing them to remain active and engaged with our platform.</p>
                <p>Tailored Rewards and Benefits: One of the key advantages of a subscription-based loyalty program is the ability to offer highly personalized rewards and benefits. By leveraging data analytics and customer insights, we can customize subscription tiers and rewards based on individual preferences, playing habits, and spending patterns. Whether it's exclusive bonuses, VIP access to events, higher sportsbook odds and casino RTP, access to live-streams, or personalized recommendations, subscribers will feel valued and appreciated, leading to increased satisfaction and loyalty.</p>
                <p>Cross-Promotional Opportunities: Partnering with other brands and businesses to offer additional perks and discounts can further enrich the value proposition of our loyalty program. Whether it's collaborating with luxury resorts, entertainment venues, or online retailers, we can create synergistic partnerships that appeal to our target audience and incentivize subscription renewals.</p>
                <p>Enhanced Engagement and Retention: A subscription-based loyalty program encourages regular interaction with our platform, as subscribers seek to maximize the benefits of their membership. This consistent engagement not only drives retention but also fosters a sense of community and belonging among our customer base. By creating a more immersive and rewarding experience, we can reduce churn rates and increase customer lifetime value over time.</p>
            </>,
    image: FoxBox
},{
    title: 'Fox through - I',
    description: 'Most thought through idea',
    content: <p>
        Introducing Coolbet Clubs, revolutionizing the conventional "Refer A Friend" program with a more engaging approach. VIPs now have the opportunity to incentivize their friends with a unique interactive experience. Gone are the days of bland referral codes; instead, members unlock tiers of incentives as a group, tailored to their specific network's preferences. Through turnover-based competitions, players within each segment collaborate towards common goals, fostering camaraderie and friendly competition. With a minimum requirement of 10 players to kickstart rewards, Coolbet Clubs not only encourages group participation but also strengthens bonds among like-minded VIPs and their peers.</p>,
    author: 'Shane Gordon',
    readMore: <>
        <p>Coolbet Clubs</p>
        <p>An expansive and more interactive “Refer A Friend” Program</p>
        <p>A way to incentivize VIP’s to have their friends join instead of just a boring referral code of $500 bonus for first $500 deposit</p>
        <p>Unlock tiers of incentives as a group</p>
        <p>Can customize these tiers to the group</p>
        <p>Initial sign up code that adds players to segment</p>
        <p>Players in segment compete in turnover based competitions to work towards a common goal</p>
        <p>Many of my VIPs are in networks of like minded and equally affluent peers</p>
        <p>Minimum of 10 players needed for Club to begin earning rewards</p>
    </>,
    image: ThoughtTrough
},{
    title: 'Fox through - II',
    description: 'Most thought through idea',
    content: <p>
        The Coolbet Loyalty Program introduces two points systems: Cool Wheel and Monthly Loyalty Points with Top Level Raffle. Customers can redeem Loyalty Points in the Cool Shop for merchandise, bonuses, and free spins. Progressing through three levels (Mouse, Fox, and Polar Bear) unlocks corresponding rewards, with multiplier points earned monthly. A weekly Cool Wheel offers rewards like free bets and spins for active customers. Success is gauged by increased turnover and participation in monthly raffles, while personalized loyalty statuses and special prizes for occasions like birthdays enhance customer engagement. Future plans include introducing Cool Points as virtual currency for promotional campaigns.</p>,
    author: 'Mikael Ketonen',
    readMore: <>
        <p>My Coolbet Loyalty program is having two different points: Cool Wheel and Monthly Loyalty Points with Top Level Raffle.<br />Customers can use Loyalty Points in a Cool Shop where they can buy some merchandise, bonuses, free spins, free bets etc. (we should implement this to be part of the Merchandise store).</p>
        <p>For tracking points I would like to have three different levels (Mouse, Fox and Polar Bear).<br />In the Cool Shop we are having "Cool boxes" where all the prizes are displayed.<br />We can offer there some basic ones with less points but also can add there something super attractive which customers can claim with higher amount of points.<br />Local teams should have a chance to add some special prizes if they want.</p>
        <p>Cool Wheel is something I would like to have every week (i.e. on Friday).<br />In the wheel we can give away free bets, free spins, bonuses, poker tickets and more Loyalty points (nothing too expensive here).<br />The wheel is open for all customers who are active and have made at least one deposit that week.</p>
        <p>Customers will claim the Loyalty points when they are using Coolbet products and make new deposits.<br />- New deposit – XX points<br />- Sportsbook TO – XX points<br />- Casino TO – XX points<br />- Virtual Sports TO - XX points<br />- Poker TO – XX points<br />- Refer a Friend – XX points<br />- Use every product during the month – XX points</p>
        <p>Customers will get multiplier points next month if they raise the level up.<br />One period is one month.<br />We try to keep them to be in Polar Bear level every month.<br />Mouse: Get normal points<br />Fox: Get +10% points<br />Polar Bear: Get +20% points</p>
        <p>If the customers reach the Polar Bear level, they are also part of the Loyalty Raffle where we are giving away some nice prizes (local teams can modify this and give something that they want: i.e. in Finland we could offer some nice Customer Trips, Merchandise etc.).</p>
        <p>We should measure the Loyalty Program's success by following the Turnover increase.<br />The idea is to get customers to "hunt" the Top level and prizes, which will make them choose Coolbet also in the future.<br />Prize boxes should include enough attractive prizes, but I think the main focus is in the monthly Raffles where we can reward active players with really nice prizes.<br />The aim is to get as many customers as possible to reach the Polar Bear level monthly.<br />Polar Bear level should be possible to reach but not too easy.<br />We don't want this to be a VIP club, but we want customers to play Weekly and also try different products.</p>
        <p>We need CRM to get our customers to know about the Loyalty program.<br />We should launch the program with some super nice raffle in the first month so we really get customers to know how the program is working.<br />I would like to add side news for at least one time per week where we are promoting the Loyalty program.</p>
        <p>Players Loyalty status should be shown every time they log in so they can easily follow their current level and how much they would need to play to reach the next level.</p>
        <p>I would like to give all the customers their own Loyalty status so they don't need to opt in for the loyalty.<br />Also part of the loyalty I would like to offer them some nice prize every time they are having a nameday or birthday.<br />Prize can be bonus or loyalty points which they can use for different rewards.</p>
        <p>In the future I would like to see that we can give Cool Points as prize for customers who are participating in some campaigns and promotions.<br />Let's make the Cool Points to be a new virtual money :)</p>
    </>,
    image: ThoughtTrough,
},{
    title: 'Phoenix',
    description: 'Best reborn idea',
    content: <p>
        I propose two enhancements for the Loyalty Program: a previously developed framework from 2017 or 2018, and additional suggestions including exclusive events, personalized rewards, tiered rewards, milestone bonuses, exclusive merchandise, early feature access, partner benefits, and gamification elements.</p>,
    author: 'Sten Tomingas',
    readMore: <>
        <p>I've got 2 ideas – one isn't really mine, but it's worth mentioning.<br />The other is just suggestions for what could be added to the Loyalty Program.</p>
        <p>Firstly, I've attached a Loyalty Program framework that was created back in 2017 or 2018.<br />As I mentioned, the original idea belongs to someone else, as I wasn't even working in Coolbet during that time.<br />I think it's a pretty good starting point.</p>
        <p>In addition to that, I think our loyalty program could include:</p>
        <ul>
            <li>Exclusive Events: Offer VIP members access to exclusive events such as celebrity meet and greets, invitation-only tournaments or luxury experiences like yacht cruises or private dining events.</li>
            <li>Personalized Rewards: Utilize data analytics to tailor rewards based on individual preferences and betting habits. This could include personalized bonuses, free bets on preferred sports teams or discounts on favorite casino games.</li>
            <li>Tiered Rewards System: Implement a tiered loyalty program where members can unlock increasingly valuable rewards as they climb higher tiers. This encourages continued engagement and provides a sense of progression.</li>
            <li>Special Bonuses for Milestones: Reward customers when they reach significant milestones, such as their anniversary as a member, hitting a certain amount wagered or achieving a specific number of wins.</li>
            <li>Exclusive Merchandise: Provide members with the opportunity to redeem loyalty points for exclusive merchandise such as branded apparel, electronics or luxury items.</li>
            <li>Early Access to New Features: Give loyal members early access to new games, betting options or platform features before they are available to the general public.</li>
            <li>Partner Benefits: Forge partnerships with other businesses to offer additional benefits to loyalty program members. This could include discounts at restaurants, hotels, spas or entertainment venues.</li>
            <li>Gamification: Incorporate gamification elements into the loyalty program, such as challenges, leaderboards, or achievements to make earning rewards more engaging and entertaining.</li>
        </ul>

    </>,
    image: RebornIdea
},{
    title: 'Copycat',
    description: 'Best competitor analysis',
    content: <p>

        Duolingo's effective systems, like the daily streak counter and leaderboards, inspire consistent engagement and competition. Implementing similar mechanisms in Coolbet could incentivize regular play and foster competition among users, potentially boosting overall engagement on the platform.</p>,
    author: 'Danny Hobo',
    readMore: <>
        <p>Duolingo, as weird as that may sound, has a few systems in play that work really well.<br />The most simple and simultaneously most important being is the daily streak counter.<br />Every day, when you complete at least one lesson, your daily streak increases by 1. you miss a day? you go back to 0.<br />When you reach certain milestones, such as a 10 day streak, 100 day streak, etc; you get rewards. Last week I got a reward; 3 days of unlimted access to Duolingo Plus, their paid version of the app ( I am a free version user, obv) . Not sure what kind of reward I would've gotten if I was paying user already, but other rewards have traditionally been gems, the duologino in-app currency which you can use to unlock avatars etc.</p>
        <p>How would this translate to Coolbet?<br />implement the same streak, and give out rewards on milestones.<br />play 7 days in a row? here are 5 free spins.<br />play 50 days in a row? here are 50 free spins.<br />etc etc. the longer your streak, the better the reward.<br />of course, the "play X days in a row" should be with T&C; only a minimum amount of money spent should count as a day played</p>
        <p>Why does this work?<br />People are playing every day :)</p>
        <p>Second idea we can take from Duolingo are their leaderboards.<br />They have 10 different leaderboards: Bronze, Silver, Gold, Sapphire, Ruby, Emerald, Amethyst, Pearl, Obsidian, and Diamond.<br />Every new user will automatically be placed in the Bronze leaderboard. this leaderboard consists of 30 players if I'm not mistaken, and each lesson you complete nets you some XP. after one week, the 7 top players will go to Silver leaderboard, the rest stays in Bronze. then a new week start, you are now playing in Silver leaderboard: the top 7 goes to Gold leaderboard, the bottom 7 get relegated to Bronze leaderboard, and so on, and so on.<br />They (used to) give out gems to the top 3 finishers in each leaderboard.</p>
        <p>How would this translate to Coolbet?<br />implement a similar system; have leaderboards in various levels. Maybe 10 levels is a bit too much, but who knows. put  a limited amount of people in a leaderboard, maybe also 30 ?<br />same scoring system as in Duolingo applies; you get XP / points / whatever on making casino spins, sportsbook bets, etc. If you finish in top 7, you go to new leaderboard in the next week. If you finish in bottom 7, you go to previous leaderboard the next week.<br />we can give out rewards to top 3 in each leaderboard.<br />in Bronze level: <br />1st place gets you 5 free spins<br />2nd palce gets you 3 free spins<br />3rd place gets you 1 free spin.<br />the higher your leaderboard level, the higher your rewards.<br />so in Diamond leaderboard:<br />1st place gets you 100 free spins<br />2nd palce gets you 75 free spins<br />3rd place gets you 50 free spins.<br />Leaderboards get updated realtime, so after each spin / bet result, you can see where you are ranked, and what your possible reward is (this is how casino race works as well)</p>
        <p>Why does this work?<br />People like to compete with each other, this can encourage people to play more in order to finish on top of their leaderboards.<br />Having multiple levels of leaderboards encourages people to play more, in order to go to a higher leaderboard with higher rewards, you need to play often.<br />If we follow Duolingo's example; there you have to play at least 10 weeks in a row ánd finish in top 7 each week in order to even be in the highest possible leaderboard. If we have a similar system then we can be sure that in our leaderboards only the users who play most will be in the top league, and they deserve to be rewarded for that</p>
    </>,
    image: CompetitorAnalysis
}]

export const YOUR_VOICE_TOPICS: YourVoiceTopicType[] = [{
    title: 'Loyalty program - [ENDED]',
    contents:[{
        title: 'Overview',
        content: <>
            <p>
                We are thrilled to announce that we are kicking off with one of hackathon’s idea - <strong>Loyalty Program</strong> and would love your input!<br />
                Your feedback is the key to help us shaping this exceptional program into something truly extraordinary. ✨
            </p>

            <p>
                Share your thoughts and insights through this <a className="regular-link" href="https://forms.gle/uJ4W224tN5eDvTpz5" target="_blank">Google Form</a> and help us uncover innovative paths we may have missed.
            </p>

            <p>
                Share your insights by <strong>1 March 2024</strong>, for not only to contribute to the program's success but also earn you a chance to be recognized for your innovative spirit!! 🏆
            </p>

            <p>
                Remember, your feedback is always welcome, even beyond the deadline.
            </p>
            <p>
                Thanks for being part of the journey!
            </p>

            <a href="https://forms.gle/uJ4W224tN5eDvTpz5" className="cta" target="_blank">Submit your idea</a>
        </>
    },{
        title: 'Extra info',
        content: <>
            <p>
                Need help getting started?
            </p>
            <p>
                We created a set of questions to you generate an idea and formulate your answers
            </p>
            <a href="https://docs.google.com/document/d/1dJkwzVAAcO2Alvn-kz-G5mTF8W1pXLeXoyGs1vgZ38E/edit?usp=sharing" className="cta cta-secondary" target="_blank">View questions</a>
        </>
    },{
        title: 'Results',
        content: <YourVoiceResults />
    }]
},{
    title: 'Your idea here',
    contents:[{
        title: 'Overview',
        content: <>
            <p>
                Do you have cool ideas that Coollab could help you with?
            </p>
            <p>
                Don’t hesitate to reach out to our members
            </p>
            <NavLink onClick={scrollToTop} to="/team" className="cta">View members</NavLink>
        </>
    }]
}]
