import React from 'react';
import Hero from "../components/hero/Hero";
import styles from './TopicsPage.module.css'
import classNames from "classnames";
import FAQTopicList from "../components/faq/FAQTopicList";
import {pageTitleStore} from "../stores/stores";

const FAQ = () => {
    pageTitleStore.set('FAQ')

    return (
        <>
            <Hero title="FAQ" subTitle="Answers to all of your questions" />
            <div id='faq' className={classNames('container', 'mainContent', 'flexGrow', styles.wrapper)}>
                <FAQTopicList />
            </div>
        </>
    );
}

export default FAQ;
