import React from 'react';
import styles from './MembersList.module.css'
import { MEMBERS } from "../../content/members";
import MemberCard from "./MemberCard";

const MembersList = () => {

    return (
        <div className={styles.wrapper}>
            {MEMBERS.map((member) => {
                return <MemberCard member={member} key={member.name} />
            })}
        </div>
    );
}

export default MembersList;
