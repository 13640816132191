import React from 'react';
import Hero from "../components/hero/Hero";
import styles from './TopicsPage.module.css'
import classNames from "classnames";
import JudgesList from "../components/judges/JudgesList";
import EventInfoList from "../components/eventInfo/EventInfoList";
import {pageTitleStore} from "../stores/stores";

const Ideas = () => {
    pageTitleStore.set('Ideas')

    return (
        <>
            <Hero title="Event Info" subTitle="What to expect at this year's hackathon" />
            <div className={classNames('container', 'mainContent', 'flexGrow', styles.wrapper)}>
                <div className={styles.section} id="judges">
                    <div className={styles.sectionTitle}>
                        <h2>Judges</h2>
                        <p>Every Tuesday at 10:00 (EET) we introduce a cool new judge. Keep coming back to find out who's up next</p>
                    </div>
                    <JudgesList />
                </div>

                <div className={classNames(styles.section)} id="info">
                    <div className={styles.sectionTitle}>
                        <h2>Info</h2>
                    </div>
                    {/*<div className={classNames(styles.section, styles.row)}>*/}
                    {/*    <TopicTabs topics={EVENT_INFO_TOPICS} store={eventInfoActiveTabStore} />*/}
                    {/*    <Questions questions={activeTab.questions} />*/}
                    {/*</div>*/}

                    <EventInfoList />
                </div>
            </div>
        </>
    );
}

export default Ideas;
