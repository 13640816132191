import React from 'react';
import styles from './SlackButton.module.css'
import slackIcon from "../../images/icons/slack_menu.svg";

const SlackButton = () => {

    return (
        <a className={styles.wrapper} href="https://coolbet.slack.com/archives/C029M8TEYCV" target="_blank" rel="noreferrer">
            <img src={slackIcon} alt="Join us on Slack"/>
            <span className={styles.text}>Join us on Slack</span>
        </a>
    );
}

export default SlackButton;
