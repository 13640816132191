import React from "react";

export interface Topic {
    title: string;
    questions: Question[];
}

export interface Question {
    question: string;
    answer: string;
}

export interface FAQ_Topic {
    title: string;
    questions: FAQ_Question[];
}

export interface FAQ_Question {
    question: string;
    answer: React.ReactNode;
}

export const EVENT_INFO_TOPICS: Topic[] = [
    {
        title: 'Location',
        questions: [
            {
                question: `What is the theme of this year's hackathon?`,
                answer: `The theme this year is Porto Franco office`
            },
            {
                question: `What are themes?`,
                answer: `This is a very good question and we don't have an answer ready`
            },
            {
                question: `Question 3`,
                answer: `This is a very good question and we don't have an answer ready`
            },
            {
                question: `Question 4`,
                answer: `This is a very good question and we don't have an answer ready`
            },
            {
                question: `Question 5`,
                answer: `This is a very good question and we don't have an answer ready`
            }
        ]
    },
    {
        title: 'Criteria',
        questions: [
            {
                question: `Question 1`,
                answer: `Answer 1`
            },
            {
                question: `Question 2`,
                answer: `Answer 2`
            },
            {
                question: `Question 3`,
                answer: `Answer 3`
            },
            {
                question: `Question 4`,
                answer: `Answer 4`
            },
            {
                question: `Question 5`,
                answer: `Answer 5`
            },
        ]
    },
    {
        title: 'Prizes',
        questions: [
            {
                question: `Question 1`,
                answer: `Answer 1`
            },
            {
                question: `Question 2`,
                answer: `Answer 2`
            },
            {
                question: `Question 3`,
                answer: `Answer 3`
            },
            {
                question: `Question 4`,
                answer: `Answer 4`
            },
            {
                question: `Question 5`,
                answer: `Answer 5`
            },
        ]
    }
]

export const FAQ_TOPICS: FAQ_Topic[] = [
    {
        title: 'Basics',
        questions: [
            {
                question: `Is it required to be in the office, or can I join remotely?`,
                answer: <>
                            <p>
                                Yes, for this hackathon, participants are required to be physically present in the office.
                            </p>

                    <p>
                        We believe that in-person collaboration and real-time interactions foster a more dynamic and creative atmosphere, leading to better project outcomes and a more enriching experience for all participants.
                    </p>

                    <p>
                    Being present in the office allows you to interact directly with your team members, mentors, and other participants, fostering a conducive environment for creativity and innovation. Additionally, the in-office setting provides access to dedicated resources and facilities that enhance the hackathon experience.
                    </p>

                    <p>
                    We understand that remote participation can offer convenience for some, but for this specific event, the in-office format is designed to optimize teamwork, networking, and the overall hackathon experience. We encourage you to make the most out of this opportunity by participating in person and being part of an exciting and collaborative journey throughout the event.
                    </p>
                </>
            },
            {
                question: `Can I spend the night in the office?`,
                answer: <>
                    <p>
                    While the office is open 24/7 during the hackathon, we don't provide overnight accommodations.
                    </p>

                    <p>
                    However, the extended office hours offer a conducive workspace for collaboration and creativity. Make the most of this opportunity to immerse yourself in the creative process and turn your innovative ideas into reality. Remember to take breaks and prioritize your well-being during the event.
                    </p>
                </>
            },
            {
                question: `How should I present my idea?`,
                answer: <>
                    <p>
                    You'll have 90 seconds to pitch it, and you can use one slide to support your presentation.
                    </p>

                    <p>
                    The key to a successful pitch is to be passionate about your idea and effectively communicate its essence within the time limit. Your pitch should be clear, concise, and captivating, capturing the interest of the audience and potential team members.
                    </p>

                    <p>
                    Don't worry about having all the details perfect at this stage; focus on highlighting the core value and potential impact of your idea. The hackathon is an excellent opportunity to refine and develop your project further, and your initial pitch sets the stage for an exciting journey of innovation.
                    </p>

                    <p>
                    So, be ready to share your enthusiasm and ideas with the participants, and make the most of your 90 seconds to make a memorable impression!
                    </p>
                </>
            },
            {
                question: `How can I vote for the best idea?`,
                answer: <>
                    <p>
                    Voting for the best idea will be conducted in person after the presentations on Friday. Please ensure you have your mobile device with you to participate in the voting process.
                    </p>

                    <p>
                    After all the presentations, you'll have the opportunity to cast your vote for the idea that you believe deserves to be recognized as the best. Your vote will play a crucial role in determining the winning project, so make sure to be present and ready to participate in this exciting aspect of the hackathon.
                    </p>
                </>
            },
        ]
    },
    {
        title: 'Idea',
        questions: [
            {
                question: `Are there specific criteria for submitting an idea?`,
                answer: <>
                    <p>
                        When submitting your idea for the hackathon, there are no rigid criteria apart from ensuring that it aligns with our company values. We highly encourage participants to think creatively and freely, as we welcome a diverse range of innovative concepts to be presented.
                    </p>

                    <p>
                        For those aiming to increase their chances of winning the hackathon, we recommend reviewing the judging criteria provided on the <a href="/event-info">Event Info</a> page. These criteria outline the key factors that our panel of judges will consider when evaluating the ideas and projects. Keeping these criteria in mind while refining and tailoring your idea can help it stand out during the judging process.
                    </p>

                    <p>
                    While there are no strict guidelines for idea submission, aligning your concept with our company values and being mindful of the judging criteria can significantly enhance your chances of creating a winning and impactful project during the hackathon. We're excited to see the creativity and passion you bring to the event!
                    </p>
                </>
            },
            {
                question: `Can I submit more than one idea?`,
                answer: <>
                    <p>
                    Absolutely!
                    </p>

                    <p>
                    We welcome participants to submit more than one idea for the hackathon. However, please keep in mind that while you can submit multiple ideas, each participant or team can only work on one idea during the event. This approach ensures fairness and allows all participants to focus their efforts on developing a single project effectively.
                    </p>

                    <p>
                    So, feel free to bring forward your creativity and innovative thinking by submitting multiple ideas. We're excited to see the diverse range of concepts you'll come up with. Once all ideas are submitted, you'll have the opportunity to select the one you're most passionate about and work collaboratively with your team to turn it into a reality during the hackathon.
                    </p>
                </>
            },
            {
                question: `What if nobody likes my idea?`,
                answer: <>
                    <p>
                    Don't worry if your idea doesn't garner immediate interest. In a hackathon, collaboration and team dynamics are at the heart of innovation. If your idea doesn't resonate with others, you're more than welcome to join another group.
                    </p>

                    <p>
                    Hackathons are all about exploring different concepts and joining forces with like-minded individuals. Sometimes, ideas need a fresh perspective or can be combined with others to create something truly exceptional. By joining another group, you get the opportunity to contribute your skills and expertise to a different project and potentially discover new synergies.
                    </p>
                </>
            },
            {
                question: `How 'ready' does my idea need to be before the event starts?`,
                answer: <>
                    <p>
                    Your idea can be at any stage of development when you join the hackathon. Whether it's a simple concept or a fully fleshed-out plan with mockups, there's ample room for creativity and ideation during the event.
                    </p>

                    <p>
                    We understand that hackathons are all about exploring innovative solutions and embracing the spirit of collaboration. You don't need to have a fully polished idea before you arrive; in fact, some of the best projects evolve from simple concepts and grow into something remarkable as teams work together.
                    </p>
                </>
            },
            {
                question: `How can I vote for the best idea?`,
                answer: <>
                    <p>
                    Voting for the best idea will be conducted in person after the presentations on Friday. Please ensure you have your mobile device with you to participate in the voting process.
                    </p>

                    <p>
                    After all the presentations, you'll have the opportunity to cast your vote for the idea that you believe deserves to be recognized as the best. Your vote will play a crucial role in determining the winning project, so make sure to be present and ready to participate in this exciting aspect of the hackathon.
                    </p>
                </>
            },
            {
                question: `What will happen to the ideas at the end of the event?`,
                answer: <>
                    <p>
                    At the conclusion of the event, the fate of the ideas will depend on their readiness and quality, particularly if they have evolved into a minimum viable product (MVP) during the hackathon.
                    </p>

                    <p>
                    If your idea or MVP shows significant promise and is well-prepared, it may be considered for further development. Some teams might be motivated to continue working on their projects after the hackathon, and we encourage such initiative.
                    </p>

                    <p>
                    Additionally, the committee will strive to support the most promising ideas by presenting them to management and product owners (POs) for further consideration. While there are no guarantees, we will advocate for the recognition and exploration of the most innovative and impactful ideas.
                    </p>

                    <p>
                    We want to foster an environment where creativity thrives, and promising ideas have the potential to evolve beyond the hackathon. So, give your best during the event, and your idea could become a stepping stone towards broader opportunities!
                    </p>
                </>
            },
        ]
    },
    {
        title: 'Participation',
        questions: [
            {
                question: `Who can participate / I’m not a developer, can I still join?`,
                answer: <>
                    <p>
                    Absolutely!
                    </p>

                    <p>
                    Our hackathon welcomes participants from diverse backgrounds and skill sets. While developers play a crucial role in building the projects, we encourage individuals with various expertise to join the event. You can participate as a project manager, team lead, spokesperson, market researcher, creative designer, or in any other role that contributes to the success of the project.
                    </p>

                    <p>
                    Hackathons thrive on the synergy of diverse talents and perspectives. Whether you are a seasoned professional or a newcomer eager to contribute, your skills and ideas are valuable assets to the teams. Collaborating with developers, you can leverage your expertise to provide valuable insights, business strategies, user experience perspectives, and more.
                    </p>

                    <p>
                    So, don't hesitate to join! Embrace the opportunity to work collaboratively and create something amazing with like-minded individuals during the hackathon. Together, we can turn innovative ideas into reality!
                    </p>
                </>
            },
            {
                question: `Do I have to pre-register if I don’t have my own idea?`,
                answer: <>
                    <p>
                    Yes, pre-registration is required for all participants, regardless of whether you have your own idea or not.
                    </p>

                    <p>
                    By pre-registering, you secure your spot in the hackathon and enable us to tailor the event to accommodate the interests and preferences of all participants. So, whether you come with a unique idea or are eager to collaborate with others, we look forward to having you on board for this exciting hackathon experience!
                    </p>
                </>
            },
            {
                question: `Is there a limit on how many people can participate?`,
                answer: <>
                    <p>
                    Yes, there is a limited number of spots available for the hackathon. We recommend booking your spot as soon as the registration opens to secure your participation.
                    </p>

                    <p>
                    Hackathons are highly popular events, and spots can fill up quickly. By registering early, you ensure that you don't miss out on this exciting opportunity to be part of the event. Once the spots are filled, we might not be able to accommodate additional participants due to logistical constraints.
                    </p>

                    <p>
                    So, mark your calendar and be ready to book your spot as soon as registration opens to guarantee your place in the hackathon. We can't wait to have you join us for a memorable and innovative event!
                    </p>
                </>
            },
            {
                question: `Do I need permission from my manager to sign up?`,
                answer: <>
                    <p>
                    While it's not mandatory to obtain permission from your manager to sign up, we recommend informing them, especially if there are potential work duties or responsibilities that may require your attention during the hackathon.
                    </p>

                    <p>
                    Open communication with your manager allows for better planning and coordination, ensuring that any work-related commitments are appropriately managed during the event. It also provides an opportunity for your manager to support your participation and potentially offer flexibility to fully engage in the hackathon.
                    </p>

                    <p>
                    We understand that your professional responsibilities are essential, and we encourage a responsible approach to participating in the hackathon. By keeping your manager informed, you can strike a balance between your work commitments and making the most of this exciting experience.
                    </p>
                </>
            },
            {
                question: `When is the deadline to join?`,
                answer: <>
                    <p>
                    The deadline to join the hackathon is September 8th, but spots are limited and filled on a first-come, first-served basis.
                    </p>

                    <p>
                    Register early to secure your participation and make the most of this exciting event, don't miss out!
                    </p>
                </>
            },
            {
                question: `What if I change my mind and cannot join the event?`,
                answer: <>
                    <p>
                    If you find yourself unable to join the hackathon after registering, we kindly request you to inform us as soon as possible. Please reach out to our <a href='/about-us'>committee members</a> to let them know about the change in your participation status.
                    </p>

                    <p>
                    We understand that circumstances can change, and it's essential for us to have an accurate count of participants to plan the event effectively. By notifying us promptly, you help us manage the logistics and make necessary adjustments to ensure a smooth and enjoyable hackathon experience for all attendees.
                    </p>
                </>
            }
        ]
    },
    {
        title: 'Groups / Teams',
        questions: [
            {
                question: `How many people can be in a group?`,
                answer: <>
                    <p>
                    The ideal group size for the event is between 3 to 6 people.
                    </p>

                    <p>
                    This range allows for effective collaboration, engagement, and ensures that everyone can actively participate in the activities and discussions. We understand that some groups might have fewer members interested in joining the event. If you have a smaller group, please don't hesitate to contact us. We are more than happy to accommodate smaller groups and tailor the event experience to ensure that everyone has a fantastic time. Our goal is to create a welcoming and inclusive environment where all attendees can make the most out of the event.
                    </p>
                </>
            },
            {
                question: `Can I participate alone/as single team?`,
                answer: <>
                    <p>
                    While we encourage collaboration, we are open to considering individual or single-team participation if you can make a compelling case for it.
                    </p>

                    <p>
                    Hackathons provide an opportunity for networking and leveraging diverse skills, but we value your enthusiasm and commitment to the event regardless of your choice.
                    </p>
                </>
            },
            {
                question: `How are teams arranged?`,
                answer: <>
                    <p>
                    After all the ideas have been presented, you will have the opportunity to show interest in the ideas you like. The idea authors will then have the autonomy to decide who will join their teams based on the expressed interest.
                    </p>

                    <p>
                    This process allows participants to connect with projects that resonate with their skills and interests. It promotes a collaborative environment where team members share a passion for the same idea and can work cohesively to bring it to fruition
                    </p>
                </>
            },
            {
                question: `How do I manage a team?`,
                answer: <>
                    <p>
                    To effectively manage your team, we recommend assigning roles to each participant, such as designer, team lead, developer, etc. This division of responsibilities will help streamline the project's development and ensure that everyone contributes to their strengths.
                    </p>

                    <p>
                    Throughout the hackathon, there will be recurring checkups where organizers will be available to provide assistance and support in managing your team. These checkups offer an opportunity to discuss progress, address any challenges, and receive guidance to keep the project on track.
                    </p>

                    <p>
                    Remember, effective communication and collaboration are essential for successful team management. Emphasize open dialogue, encourage creative input from all members, and foster a positive and inclusive team environment.
                    </p>
                </>
            },
        ]
    },
    {
        title: 'Winners / Prizes',
        questions: [
            {
                question: `What are the prizes?`,
                answer: <>
                    <p>For detailed information about the prizes and rewards offered for the hackathon, please visit our <a href='/prizes'>Prizes</a> page.</p>
                </>
            },
            {
                question: `What are the criterias to win?`,
                answer: <>
                    <p>
                    You can find the detailed criteria the judges will assess on our <a href='/event-info'>Event info</a> page.
                    </p>

                    <p>
                    The main evaluation points include: <br/>
                    1. Originality<br/>
                    2. Usefulness<br/>
                    3. Readiness<br/>
                    4. Viability<br/>
                    5. Presentation
                    </p>

                    <p>
                    These criteria serve as the foundation for the judges' assessment, and understanding them will help you tailor your project for a strong presentation. Be sure to review the criteria and showcase your best work during the hackathon!
                    </p>
                </>
            },
            {
                question: `Who decides who wins?`,
                answer: <>
                    <p>
                        The winners of the hackathon will be determined by a jury of experts. You can find more details about our esteemed jury members on the <a href='/event-info'>Event info</a> page.
                    </p>

                    <p>
                    Our jury members will carefully evaluate each project based on predefined criteria, innovation, execution, impact, and overall feasibility. They will consider factors such as the project's creativity, technical prowess, problem-solving approach, and how well it aligns with the hackathon's objectives.
                    </p>

                    <p>
                    The judging process is designed to be fair, impartial, and transparent, ensuring that the most deserving projects are recognized and celebrated. We're excited to witness the exceptional solutions and ideas brought forth by our participants, and we eagerly anticipate unveiling the well-deserved winners with the help of our esteemed jury!
                    </p>
                </>
            },
            {
                question: `How is the prize split in the team?`,
                answer: <>
                    <p>The prize will be divided equally among the winning team members. Each member of the team will receive an equal share of the prize as a reward for their collective efforts and contributions to the project's success.</p>
                </>
            },
        ]
    },
];
