import schedulePlaceholderSVG from '../images/icons/schedulePlaceholder.svg'

export interface ScheduleTab {
    title: string;
    subtitle: string;
    sections: ScheduleSection[]
}

export interface ScheduleSection {
    image: string;
    title: string;
    subtitle: string;
    text: string;
}

export const SCHEDULE_TABS: ScheduleTab[] = [
    {
        title: 'Wed, 13.09',
        subtitle: 'Wednesday, 12th September 2023',
        sections: [
            {
                image: schedulePlaceholderSVG,
                title: 'Welcoming of participants',
                subtitle: '09:30-10:00 - Porto Franco, 2nd floor auditorium',
                text: `Check-in, registration, morning coffee, badge collection.`,
            },
            {
                image: schedulePlaceholderSVG,
                title: 'Kick-off',
                subtitle: '10:00-11:00 - Porto Franco, 2nd floor auditorium',
                text: `Get ready for an exciting session of 90-second pitches from our idea submitters.\n
                        As a spectator, your task is simple: pay close attention and keep an eye out for the idea that resonates with you the most! 
                        You can ask additional questions later.`,
            },
            {
                image: schedulePlaceholderSVG,
                title: 'Grouping up!',
                subtitle: '11:00-12:00 - Porto Franco, 2nd floor auditorium',
                text: `Each idea author will spread out and wait for interested individuals to approach them with any additional questions or to express their enthusiasm.

    Concurrently, participants are encouraged to explore the room, gathering more information about the various projects on display.
    Ultimately, the objective is for each participant to select the group whose project captivates them the most.`,
            },
            {
                image: schedulePlaceholderSVG,
                title: '🥳 Start of hacking',
                subtitle: '12:00-00:00 - Location of your choice',
                text: `Bring your team together and select a collaborative workspace that suits your needs best.
                Define clear team roles, and then dive into your project, fuelled by creativity and determination!

                For team roles, we suggest: “Team lead”, “Designer”, Developer”, Data scientist”, “PR. Planner”, “Marketer”, “Visionary” etc.`,
            }
        ]
    },
    {
        title: 'Thu, 14.09',
        subtitle: 'Thursday, 13th September 2023',
        sections: []
    },
    {
        title: 'Fri, 15.09',
        subtitle: 'Thursday, 13th September 2023',
        sections: []
    }
]




