import React from 'react';
import styles from './HeroHomepage.module.css';
import backgroundMobile from '../../images/background_mobile_new.png';
import backgroundDesktop from '../../images/background_desktop_new.png';
import coollabLogo from '../../images/logos/coollab_logo.svg'
import classNames from "classnames";

const HeroHomepage = () => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.imageWrapper}>
                <img className={styles.imageMobile} src={backgroundMobile} alt='Mobile background'/>
                <img className={styles.imageDesktop} src={backgroundDesktop} alt='Desktop background'/>
            </div>
            <div className={styles.textWrapper}>
                <img className={styles.logo} src={coollabLogo} alt="Coollab Logo"/>


                <h1 className={styles.title}>Stay<br/>Innovative</h1>
                {/*<p className={classNames('heroSubTitle', 'heroSubTitleHomepage')}>13-15 September</p>*/}
                {/*<a className={styles.cta} href="https://forms.gle/aFfLeyjtWwpH4dvy5" target="_blank" rel="noreferrer">Sign up now</a>*/}
            </div>
        </div>
    );
}

export default HeroHomepage;
