import React, { Fragment } from "react";

export const replaceNewLines = (input: string) => {
    return input.split('\n').map((item, key) => {
        return <Fragment key={key}>{item}<br/></Fragment>
    })
}


export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smooth scrolling behavior, if supported
    });
};