import React, { useEffect, useState } from 'react';
import styles from './FAQQuestion.module.css';
import { FAQ_Question } from "../../content/topics";
import classNames from "classnames";
import MinusIcon from "../../images/icons/minus.svg";
import PlusIcon from "../../images/icons/plus.svg";

interface Props {
    question: FAQ_Question;
}

const FAQQuestion = ({ question} : Props) => {

    const [isOpen, setIsOpen] = useState(false);
    const [image, setImage] = useState(PlusIcon)

    useEffect(() => {
        setImage(isOpen ? MinusIcon : PlusIcon)
    }, [isOpen]);

    return (
        <div className={styles.questionWrapper}>
            <div className={styles.question} onClick={() => setIsOpen(!isOpen)}>
                <span className={styles.questionText}>{question.question}</span>
                <div className={styles.imageWrapper}><img src={image} alt="" /></div>
            </div>
            {isOpen && (
                <div className={classNames(styles.answer)}>
                    {question.answer}
                </div>
            )}
        </div>
    );
}

export default FAQQuestion;
