import React from 'react';
import styles from './HistoryList.module.css'
import {HISTORY_EVENTS} from "../../content/history";
import HistoryCard from "./HistoryCard";

const HistoryList = () => {
    return (
        <div className={styles.wrapper}>
            {HISTORY_EVENTS.map((history) => {
                return <HistoryCard history={history} key={history.title} />
            })}
        </div>
    );
}

export default HistoryList;
