import React, {useEffect, useState} from "react";
import styles from "./YourVoiceContent.module.css";
import classNames from "classnames";
import {YourVoiceTopicType} from "../../content/yourVoiceContent";

interface Props {
    topic: YourVoiceTopicType;
}

const YourVoiceContent = ({ topic }: Props) => {
    const [activeTab, setActiveTab] = useState(topic.contents[0]);

    useEffect(() => {
        setActiveTab(topic.contents[0])
    }, [topic]);


    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <h2>{topic.title}</h2>
                {topic.contents.length > 1 && (
                    <div className={styles.tabs}>
                        {topic.contents.map((content) => {
                            const isActive = content.title === activeTab.title;

                            return (
                                <div className={classNames(styles.tab, isActive && styles.active)} onClick={() => setActiveTab(content)} key={content.title}>
                                    {content.title}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            <div className={styles.contentWrapper}>
                {activeTab.content}
            </div>
        </div>
    );
}

export default YourVoiceContent;