import React from 'react';
import styles from './FAQQuestions.module.css';
import { FAQ_Question } from "../../content/topics";
import FAQQuestion from "./FAQQuestion";

interface Props {
    questions: FAQ_Question[];
}

const FAQQuestions = ({ questions} : Props) => {

    return (
        <div className={styles.wrapper}>
            {questions.map((question) => {
                return <FAQQuestion question={question} key={question.question} />
            })}
        </div>
    );
}

export default FAQQuestions;
