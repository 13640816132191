import React from 'react';
import styles from './Hero.module.css'
import classNames from "classnames";


interface Props {
    title: string;
    subTitle?: string;
}

const Hero = ({title, subTitle} : Props) => {

    return (
        <div className={styles.wrapper}>
            <div className={classNames('container', styles.textWrapper)}>
                <h1>{title}</h1>
                {subTitle && (
                    <p className='heroSubTitle'>{subTitle}</p>
                )}
            </div>
        </div>
    );
}

export default Hero;
