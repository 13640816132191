import React from 'react';
import styles from './JudgesList.module.css';
import { JUDGES } from "../../content/judges";
import JudgeCard from "./JudgeCard";
import { useStore } from "@nanostores/react";
import {
    judge1Store,
    judge2Store,
    judge3Store,
    judge4Store,
    judge5Store,
} from "../../stores/stores";

const JudgesList = () => {

    const judge1Unlocked = useStore(judge1Store);
    const judge2Unlocked = useStore(judge2Store);
    const judge3Unlocked = useStore(judge3Store);
    const judge4Unlocked = useStore(judge4Store);
    const judge5Unlocked = useStore(judge5Store);

    const onUnlock = (id: number) => {
        switch (id){
            case 1:
                judge1Store.set(true);
                break;
            case 2:
                judge2Store.set(true);
                break;
            case 3:
                judge3Store.set(true);
                break;
            case 4:
                judge4Store.set(true);
                break;
            case 5:
                judge5Store.set(true);
                break;
        }
    }

    return (
        <div className={styles.wrapper}>
            {JUDGES.map((judge) => {

                let isLocalStorageUnlocked = false;

                switch (judge.id){
                    case 1:
                        isLocalStorageUnlocked = judge1Unlocked;
                        break;
                    case 2:
                        isLocalStorageUnlocked = judge2Unlocked;
                        break;
                    case 3:
                        isLocalStorageUnlocked = judge3Unlocked;
                        break;
                    case 4:
                        isLocalStorageUnlocked = judge4Unlocked;
                        break;
                    case 5:
                        isLocalStorageUnlocked = judge5Unlocked;
                        break;
                }


                return <JudgeCard judge={judge} key={judge.id} isLocalStorageUnlocked={isLocalStorageUnlocked} onUnlock={onUnlock} />
            })}
        </div>
    );
}

export default JudgesList;
