import React, { useState } from 'react';
import styles from './EventInfoList.module.css'
import { EVENT_INFOS } from "../../content/eventInfo";
import classNames from "classnames";


const EventInfoList = () => {
    const [activeEventInfo, setActiveEventInfo] = useState(EVENT_INFOS[0]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>
            {EVENT_INFOS.map((eventInfo) => {
                const isActive = eventInfo.title === activeEventInfo.title;

                return (
                    <div className={classNames(styles.tab, isActive && styles.active)} onClick={() => setActiveEventInfo(eventInfo)} key={eventInfo.title}>
                        {eventInfo.title}
                    </div>
                )
            })}
            </div>

            <div className={styles.contentWrapper}>
                <h4>{activeEventInfo.title}</h4>

                <div className={styles.content}>
                    {activeEventInfo.content}
                </div>
            </div>
        </div>
    );
}

export default EventInfoList;
