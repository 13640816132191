import Hackathon2023 from '../images/history/hackathon_2023.svg';
import Hackathon2022 from '../images/history/hackathon_2022.svg';
import Brainstorming2022 from '../images/history/brainstorming_2022.svg';
import Hackathon2021 from '../images/history/hackathon_2021.svg';
import Brainstorming2021 from '../images/history/brainstorming_2021.svg';
import Hackathon2023Background from '../images/history/backgrounds/hackathon_2023_bg.png';

import Hackathon2023_Submission_01 from '../images/history/submissions/hackathon_2023_01.png';
import Hackathon2023_Submission_02 from '../images/history/submissions/hackathon_2023_02.png';
import Hackathon2023_Submission_03 from '../images/history/submissions/hackathon_2023_03.png';
import Hackathon2023_Submission_04 from '../images/history/submissions/hackathon_2023_04.png';
import Hackathon2023_Submission_05 from '../images/history/submissions/hackathon_2023_05.png';
import Hackathon2023_Submission_06 from '../images/history/submissions/hackathon_2023_06.png';

export interface History {
    image: string;
    title: string;
    subtitle: string;
    content: string;
    slug?: string;
    backgroundImage?: string;
    overviewItems?: {
        title: string;
        content: React.ReactNode;
    }[]
    submissionSections?: {
        title: string;
        submissions: {
            inlineImage?: string;
            image?: string;
            title: string;
            subtitle?: string;
            content: string;
            members: string;
        }[]
    }[]
    pictures?: {
        content: string;
        url: string;
    }
}

export const HISTORY_EVENTS: History[] = [
    {
        image: Hackathon2023,
        title: 'Hackathon 2023',
        subtitle: '13-15 Sept, 2023',
        content: `We’re back again with Coollab Hackathon 🚀\nThis year, Hackathon remains a 2-in-1 event.`,
        slug: 'hackathon-2023',
        backgroundImage: Hackathon2023Background,
        overviewItems: [
            {
                title: 'Date:',
                content: '13-15 September, 2023 * 48 hours'
            },
            {
                title: 'Participants:',
                content: <>We had an awesome turnout, with <b>42 fantastic folks</b> like you jumping into the action</>
            },
            {
                title: 'Ideas submitted:',
                content: <>A grand total of <b>9 innovative</b> ideas flowed through the Hackathon – talk about an inspiring brainstorming session!</>
            },
            {
                title: 'Winners:',
                content:
                    <>
                        <b>1st Place</b>: Casino Recommended Games<br />
                        <b>2nd Place</b>: Tinder UX for Betting<br />
                        <b>3rd Place</b>: Privacy Dashboard<br />
                        <b>People's Choice</b>: Casino Recommended Games<br />
                    </>
            }
        ],
        submissionSections: [
            {
                title: 'Winners',
                submissions: [
                    {
                        image: Hackathon2023_Submission_01,
                        title: '🥇Casino Recommended Games',
                        subtitle: '1st place winner',
                        content: `The 'Recommended Games' feature in casino gaming enhances the player's experience. It analyzes player preferences and history to suggest ideal casino games that adapt over time. This ensures players stay engaged and immersed in games that align with their individual interests.`,
                        members: 'Anar Sadigli, Pooya Ostovar, Pooja Yadav, Alvar Valtna, Mauricio Sanchez, Steven Esposito',
                    },
                    {
                        image: Hackathon2023_Submission_02,
                        title: '🥈Tinder UX for Betting',
                        subtitle: '2nd place winner',
                        content: `Tinder-inspired user interface integrated into a sports betting platform. This concept combines the fun and engaging aspects of Tinder's swiping interface with the world of sports betting, creating an exciting and user-friendly experience for our users.`,
                        members: 'Nikolay Lebedev, Rasmus Raidla, Iris Elisabeth Pihelgas, Santosh Deshmukh',
                    },
                    {
                        image: Hackathon2023_Submission_03,
                        title: '🥉Privacy Dashboard',
                        subtitle: '3rd place winner',
                        content: `A Privacy Dashboard simplifies customer access to personal data, enhancing the ease of configuring privacy settings and promoting efficiency and cross-team collaboration.`,
                        members: 'Tolulope Ogundele, Israel Gabice, Sonja Itäinen',
                    }
                ]
            },
            {
                title: `People's choice`,
                submissions: [
                    {
                        image: Hackathon2023_Submission_01,
                        title: '🌟Casino Recommended Games',
                        subtitle: `People's choice`,
                        content: `The 'Recommended Games' feature in casino gaming enhances the player's experience. It analyzes player preferences and history to suggest ideal casino games that adapt over time. This ensures players stay engaged and immersed in games that align with their individual interests.`,
                        members: 'Anar Sadigli, Pooya Ostovar, Pooja Yadav, Alvar Valtna, Mauricio Sanchez, Steven Esposito',
                    }
                ]
            },
            {
                title: `Other ideas`,
                submissions: [
                    {
                        title: 'AR Advertising Campaigns',
                        content: `Exploring Augmented Reality (AR) solution to increase users engagement`,
                        members: 'Herbert-Ken Ümera, Robert Efros, Dmitri Gornakov, Saumya Rthnayake',
                    },
                    {
                        title: 'BO Permission Matrix',
                        content: `Automated Back-Office (BO) Access Control Matrix`,
                        members: 'Sajith Jeewantha, Artur Veske, Aravinda Siriwardhana, Tushar Wagh Benyamin Teymoori, Aleksandr Semenov',
                    },
                    {
                        title: 'IoT Protection',
                        content: `Explore Raspberry pi 3 to find use in the office`,
                        members: 'Reem Hisham',
                    },
                    {
                        image: Hackathon2023_Submission_05,
                        title: 'Balance to profit (B2P)',
                        content: `Effortlessly reducing unnecessary transactions and implementing intuitive bonus progress tracking. The goal: saving the company money, boosting player engagement, and streamlining manual processes.`,
                        members: 'Madis Lempu, Olavi Ottenson, Linda Mägi, Raul Kullam, Kristiina Kerma, Silver Pedak',
                    },
                    {
                        title: 'S2S Dependency Mapping',
                        content: `Creating a service-to-service (S2S) call map to visually illustrate dependencies within our system`,
                        members: 'Jevgeni Kapparov, Aleksandr Gostev, Hendrik Ütt, Aleksandr Mihhailov, Vishal Kapoor',
                    },
                    {
                        image: Hackathon2023_Submission_06,
                        title: 'Cool News',
                        content: `An internal company webpage designed for efficient information sharing. This platform aims to reduce clutter in Slack, ensuring important updates remain easily accessible and well-organized.`,
                        members: 'Alexis Matheis, Tagne Orav, Tran Minh Huy Vu, Anil Ozdemir, Vitali Gujevski, Fadel Drissi Toubbali, Martin Lillemets',
                    }
                ]
            }
        ],
        pictures: {
            content: `Hey everyone!\n
                        This year was a blast, and we've rounded up all the event pics on Google Drive.
                        Take a look and relish in the memories! 📸😊`,
            url: `https://drive.google.com/drive/folders/1uOKzg7MoB6wiUKl1DMjg6h_4PpRdC_1a?usp=drive_link`
        }
    },
    {
        image: Hackathon2022,
        title: 'Hackathon 2022 - Hack the universe',
        subtitle: '01-02 Dec, 2022',
        content: `A Fusion of Innovation and Collaboration. Explore the key moments, projects, and the spirit of creativity that defined our event in 2022.`,
    },
    {
        image: Brainstorming2022,
        title: 'Brainstorming 2022',
        subtitle: '20 Apr, 2022',
        content: `Preparing for Hackathon 2022: A Recap of the Brainstorming Event.`,
    },
    {
        image: Hackathon2021,
        title: 'Hackathon 2021',
        subtitle: '30-03 Dec, 2021',
        content: `A Fusion of Innovation and Collaboration. Explore the key moments, projects, and the spirit of creativity that defined our event in 2021.`,
    },
    {
        image: Brainstorming2021,
        title: 'Brainstorming 2021',
        subtitle: '29 Jul, 2021',
        content: `Preparing for Hackathon 2021: A Recap of the Brainstorming Event.`,
    }
]