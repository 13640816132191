import React from 'react';
import styles from './Footer.module.css'
import coollabLogo from '../../images/logos/coollab_logo.svg'
import slackIcon from '../../images/icons/slack.svg';
import { FOOTER_MENU_SECTIONS } from "../../config/menuItems";
import { NavLink } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
import classNames from "classnames";

const Footer = () => {

    return (
        <footer className={styles.footer}>
            <div className={classNames('container', styles.wrapper)}>
                <div className={styles.text}>
                    <div className={styles.imageWrapper}>
                        <img src={coollabLogo} alt="CoolLab Logo"/>
                    </div>
                    <p>
                        Coollab - Fostering Innovation since 2017.<br />
                        Join us in propelling our Innovation rocket to new heights!
                    </p>
                </div>
                <div className={styles.socials}>
                    <div className={styles.socialIcon}>
                        <a href="https://coolbet.slack.com/archives/C029M8TEYCV" target="_blank" rel="noreferrer">
                            <img src={slackIcon} alt="Join us on Slack"/>
                        </a>
                    </div>
                </div>
                <div className={styles.links}>
                    {/*{FOOTER_MENU_SECTIONS.map((section) => {*/}
                    {/*    return (*/}
                    {/*        <div key={section.name} className={styles.section}>*/}
                    {/*            <h3>{section.name}</h3>*/}

                    {/*            <div className={styles.sectionLinks}>*/}
                    {/*                {section.menuItems.map((menuItem) => {*/}
                    {/*                    return (*/}
                    {/*                        <NavHashLink className={styles.link} key={menuItem.url} to={menuItem.url}>{menuItem.name}</NavHashLink>*/}
                    {/*                    )*/}
                    {/*                })}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*})}*/}

                </div>
                <div className={styles.dividerWrapper}>
                    <div className={styles.divider}/>
                    <span className={styles.poweredBy}>Powered by Cool Lab</span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
