import React, { useEffect, useState } from 'react';
import styles from './Header.module.css'
import logo from '../../images/coollab_logo.png'
import menuHamburgerIcon from '../../images/icons/menu-hamburger.svg'
import menuCloseIcon from '../../images/icons/menu_close.svg'
import lock from '../../images/icons/lock.svg'
import { MENU_ITEMS } from "../../config/menuItems";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { loggedInUserStore, mobileMenuStore } from "../../stores/stores";
import { useStore } from "@nanostores/react";
import LoginButton from "../login/LoginButton";
import SlackButton from "../buttons/SlackButton";

const Header = () => {

    const loggedInUser = useStore(loggedInUserStore);
    const isMobileMenuOpen = useStore(mobileMenuStore);
    const [menuImage, setMenuImage] = useState(menuHamburgerIcon);

    useEffect(() => {
        if(isMobileMenuOpen){
            setMenuImage(menuCloseIcon)
        }
        else {
            setMenuImage(menuHamburgerIcon)
        }
    }, [isMobileMenuOpen])

    const handleOnClick = () => {
        mobileMenuStore.set(false);
    }

    const toggleMobileMenu = () => {
        mobileMenuStore.set(!isMobileMenuOpen)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.logoWrapper} onClick={handleOnClick}>
                <NavLink to={'/'}>
                    <img src={logo} alt="CoolLab"/>
                </NavLink>
            </div>
            <nav className={styles.navigation}>
                {MENU_ITEMS.map((menuItem) => {

                    const isDisabled = menuItem.protected && !loggedInUser;

                    return (
                        <div key={menuItem.name}>
                            <NavLink to={menuItem.url} className={({ isActive }) => {
                                return classNames(styles.navigationItem, isActive && styles.active, isDisabled && styles.disabled)
                            }}>
                                <span className={styles.spanGrid}>{menuItem.name} {isDisabled && <img src={lock} alt="Protected route" />}</span>
                            </NavLink>
                        </div>
                    );
                })}
            </nav>
            <div className={styles.login}>
                {loggedInUser && (<SlackButton />)}
                <LoginButton />
            </div>
            <div className={styles.hamburgerWrapper} onClick={toggleMobileMenu}>
                <img src={menuImage} alt="Menu" />
            </div>
        </div>
    );
}

export default Header;
