import React from 'react';
import HeroHomepage from "../components/hero/HeroHomepage";
import {useStore} from "@nanostores/react";
import {pageTitleStore} from "../stores/stores";

const Home = () => {
    pageTitleStore.set('Home')

    return (
        <>
            <HeroHomepage />
        </>
    );
}

export default Home;
