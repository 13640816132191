import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useStore } from "@nanostores/react";
import { loggedInUserStore } from "../stores/stores";
import {AUTH_ENABLED} from "../config/config";


interface Props {
    children: ReactNode
}

export const ProtectedRoute = ({children} : Props) => {

    const loggedInUser = useStore(loggedInUserStore);

    if (!loggedInUser && AUTH_ENABLED) {
        return <Navigate to={'/'} replace />;
    }

    return <>{children}</>;
};
