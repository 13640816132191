import React from 'react';
import styles from './HeroWithImage.module.css'
import classNames from "classnames";
import { replaceNewLines } from "../../utils/utils";


interface Props {
    title: string;
    subTitle: string;
    image: string;
}

const HeroWithImage = ({title, subTitle, image} : Props) => {
    return (
        <div className={styles.wrapper}>
            <div className={classNames('container', styles.contentWrapper)}>
                <div className={styles.imageWrapper}>
                    <img src={image} alt={title} />
                </div>
                <div className={styles.textWrapper}>
                    <h1 className={styles.title}>{title}</h1>
                    <p className={classNames('heroSubTitle', styles.subTitle)}>{replaceNewLines(subTitle)}</p>
                </div>
            </div>
        </div>
    );
}

export default HeroWithImage;
