import { atom } from "nanostores";
import { persistentAtom } from "@nanostores/persistent";
import { SCHEDULE_TABS } from "../content/schedule";
import { EVENT_INFO_TOPICS, FAQ_TOPICS } from "../content/topics";


export interface User {
    name: string;
    imageUrl: string;
}

export const loggedInUserStore = persistentAtom<User | undefined>('user', undefined, {
    encode: JSON.stringify,
    decode: JSON.parse,
});

export const judge1Store = persistentAtom<boolean>('judge_1_unlocked', false, {
    encode: JSON.stringify,
    decode: JSON.parse,
});

export const judge2Store = persistentAtom<boolean>('judge_2_unlocked', false, {
    encode: JSON.stringify,
    decode: JSON.parse,
});

export const judge3Store = persistentAtom<boolean>('judge_3_unlocked', false, {
    encode: JSON.stringify,
    decode: JSON.parse,
});

export const judge4Store = persistentAtom<boolean>('judge_4_unlocked', false, {
    encode: JSON.stringify,
    decode: JSON.parse,
});

export const judge5Store = persistentAtom<boolean>('judge_5_unlocked', false, {
    encode: JSON.stringify,
    decode: JSON.parse,
});


export const mobileMenuStore = atom<boolean>(false);
export const pageTitleStore = atom<string>('Home');

export const scheduleActiveTabStore = atom<string>(SCHEDULE_TABS[0].title);
export const eventInfoActiveTabStore = atom<string>(EVENT_INFO_TOPICS[0].title);
export const faqActiveTabStore = atom<string>(FAQ_TOPICS[0].title);
